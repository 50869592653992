const VERSION_CODE = '0.01';

const getBuildNumber = () => VERSION_CODE;

export const MAX_PRODUCT_SCORE = 5;
export const CARB_TO_CAL_MULTIPLIER = 4;
export const PROTEIN_TO_CAL_MULTIPLIER = 4;
export const FAT_TO_CAL_MULTIPLIER = 9;

export const MIN_GRAM_PER_HOUR = 20;
export const MAX_GRAM_PER_HOUR = 90;
export const MAX_OXIDATION_RATE = 6000;
export const MIN_OXIDATION_RATE = 1333;

export const DEFAULT_FLUIDS_PER_HOUR = 500;
export const MILLIGRAM_TO_GRAMS = 1000;
export const SECONDS_TO_HOURS = 3600;

export const INDICATOR = {
  CARBS_PER_HOUR: 'carbs_per_hour',
  ENERGY: 'energy',
  RATING: 'rating',
  FLUIDS: 'fluids',
  KCAL: 'kcal',
  CARBOHYDRATES: 'carbohydrates',
  FATS: 'fats',
  PROTEINS: 'proteins',
};

export const ACTIVITY_SOURCES = {
  ROUTE: 'route',
  WORKOUT: 'workout',
};

const constants = {
  getBuildNumber,
};

export default constants;
