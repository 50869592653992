import React from 'react';
import { ThemeProvider } from '@mui/material';
import { UserProvider } from './helpers/UserContext';
import * as Theme from './style/themes';
import DashboardContainer from './components/DashboardContainer';
import './App.css';
import { ModalProvider } from './helpers/useModal';
import { useSmartlook } from './helpers/smartlook';

const App = () => {
  useSmartlook();

  return (
    <ThemeProvider theme={Theme.theme}>
      <UserProvider>
        <ModalProvider>
          <DashboardContainer />
        </ModalProvider>
      </UserProvider>
    </ThemeProvider>
  );
};

export default App;
