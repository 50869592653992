import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import Modal from './Modal';

const DatePicker = ({ value, open, setOpen, setDate }) => (
  <Modal open={open} setOpen={setOpen} maxWidth="xs" sx={{ display: 'unset', p: 0, flex: '0 0 auto' }} className="unset">
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        openTo="day"
        value={value}
        onChange={newValue => {
          setDate(newValue.startOf('day'));
          setOpen(false);
        }}
        renderInput={params => <TextField {...params} />}
      />
    </LocalizationProvider>
  </Modal>
);

export default DatePicker;
