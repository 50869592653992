import { Box, Card, Divider, Typography, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import Indicator from '../Indicator';
import Modal from '../common/Modal';
import * as Theme from '../../style/themes';
import { DrinkIcon, EnergyBarIcon, GelIcon } from '../ProductIcons';
import useAxios from '../../helpers/api';
import { getIndicators } from '../../helpers/indicators';
import { INDICATOR, ACTIVITY_SOURCES } from '../../constants/constants';
import TimeDistanceToggle from '../TimeDistanceToggle';
import { getFormattedTime, getFormattedDistance } from '../../helpers/localization';
import { translate } from 'helpers/localization';

const ProductList = ({ products, viewMode }) => (
  <List sx={{ p: 0, flexDirection: 'column' }}>
    {products.map(({ time, distance, gram, ml, product: { label, description, calories, carbohydrates, fat, protein, tags, ...product } }, i) => {
      const amount = { gram, ml, piece: 1 }[product.ingredientsQtyUnit];
      const carbsPerUnit = carbohydrates / product.ingredientsQty;
      const carbs = amount * carbsPerUnit;
      return (
        <>
          <Divider component="li" />
          <ListItem key={i} sx={{ p: '1em', height: '4em' }}>
            <ListItemAvatar sx={{ flex: 0, minWidth: 'unset', alignItems: 'center' }}>
              <Avatar
                sx={{
                  width: '2em',
                  height: '2em',
                  fontWeight: 'bold',
                  backgroundColor: distance === -1 || time === -1 ? Theme.colorRed : Theme.colorBlue,
                  fontSize: '0.8em',
                }}
              >
                {i + 1}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              sx={{
                flex: `0 0 ${viewMode === 'distance' ? '3.5' : '2.5'}em`,
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
              primary={distance === -1 || time === -1 ? '-' : viewMode === 'distance' ? `${Math.round(distance / 1000)} km` : getFormattedTime(time, false)}
              primaryTypographyProps={{ fontSize: '0.8em', fontWeight: 800 }}
            />
            <ListItemAvatar
              sx={{
                flex: '0 0 3em',
                minWidth: 'unset',
                justifyContent: 'center',
              }}
            >
              {tags?.toLowerCase().includes('gel') ? (
                <GelIcon sx={{ color: Theme.colorGradientGreen }} />
              ) : tags?.toLowerCase().includes('drink') ? (
                <DrinkIcon sx={{ color: Theme.colorBlue }} />
              ) : tags ? (
                <EnergyBarIcon sx={{ color: Theme.colorGradientBlueGreen }} />
              ) : (
                <EnergyBarIcon sx={{ color: Theme.colorGradientBlueGreen }} />
              )}
            </ListItemAvatar>
            <ListItemText
              sx={{ flexDirection: 'column', flex: '1 0 4em' }}
              primary={label}
              secondary={description?.startsWith(label) ? description.replace(label, '').trim() : description}
              primaryTypographyProps={{ fontSize: '0.8em', fontWeight: 800 }}
              secondaryTypographyProps={{ fontSize: '0.8em' }}
            />
            <ListItemText
              sx={{ flex: '0 0 3.5em', justifyContent: 'flex-end' }}
              primary={`${Math.round(carbs / 1000)} g`}
              primaryTypographyProps={{ fontSize: '0.8em' }}
            />
          </ListItem>
        </>
      );
    })}
  </List>
);

export const FoodManager = ({ activity, products }) => {
  const [viewMode, setViewMode] = useState(activity.viewMode);
  const onTimeDistanceToggle = useCallback(checked => {
    setViewMode(checked ? 'distance' : 'time');
  }, []);
  return (
    <>
      <Box sx={{ p: '1em', justifyContent: 'space-between' }}>
        <Box sx={{ flexDirection: 'column' }}>
          <Typography sx={{ fontWeight: 500 }}>Nutrition and drinks plan</Typography>
          <Typography sx={{ color: Theme.colorBlueGray, fontSize: '0.8em' }}>(Quantities in grams of carbohydrates)</Typography>
        </Box>
        {products?.length > 0 && !activity.completedActivityId && <TimeDistanceToggle onToggle={onTimeDistanceToggle} checked={viewMode === 'distance'} />}
      </Box>
      {products?.length > 0 ? <ProductList viewMode={viewMode} products={products} /> : <Box sx={{ px: '1em', pb: '1em' }}>No products added.</Box>}
    </>
  );
};

const ActivityModal = ({ activity, athlete, foodplan, setOpen }) => {
  const [imageUrl, setImage] = useState('');
  const api = useAxios();

  useEffect(() => {
    if (activity.sourceType === ACTIVITY_SOURCES.ROUTE && activity.sourceId && api) {
      api.images
        .getFromRoute(activity.userId, activity.sourceId)
        .then(res => {
          const objectURL = URL.createObjectURL(res.data);
          setImage(objectURL);
        })
        .catch(() => {
          setImage(null);
        });
    }
  }, [api, activity]);

  const products = foodplan.sort((a, b) => (a.time === -1 ? 1 : b.time === -1 ? -1 : a.time - b.time));

  const enabledIndicators = [INDICATOR.CARBS_PER_HOUR, INDICATOR.ENERGY, INDICATOR.RATING, INDICATOR.FLUIDS];

  const indicators = getIndicators(enabledIndicators, products, null, activity, false, athlete.measurementSystem);

  const durationText = getFormattedTime(activity.duration, false);
  const dateText = `${moment(activity.date).format('dddd, D MMMM YYYY, HH:mm')}`;
  const rideType = activity.rideType === 'generic' ? 'Endurance' : activity.rideType[0].toUpperCase() + activity.rideType.slice(1);

  const getIntensityText = () => {
    const { avgPowerRide, avgHeartRate } = activity;

    if (avgPowerRide) {
      return `${avgPowerRide} ${translate('activity.intensity_power')}`;
    }
    if (avgHeartRate) {
      return `${avgHeartRate} ${translate('activity.intensity_bpm')}`;
    }

    return translate('global.default').toLowerCase();
  };

  return (
    <Modal
      sx={{
        flexDirection: 'row',
        p: 2,
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      }}
      title="Training Plan"
      open={!!activity}
      setOpen={setOpen}
    >
      <Card sx={{ flexDirection: 'column', flex: '0 0 26em' }}>
        <FoodManager activity={activity} products={products} />
      </Card>
      <Box sx={{ flex: '0 0 26em', flexDirection: 'column' }}>
        <Card
          sx={{
            p: '1em',
            mb: 1,
            flexDirection: 'column',
            flex: '0 0 auto',
            justifyContent: 'space-between',
          }}
        >
          <Typography sx={{ alignSelf: 'flex-start', fontWeight: 500 }}>Summary</Typography>

          <Box
            sx={{
              flex: '1 1 auto',
              justifySelf: 'stretch',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            {indicators.slice(0, 2).map(({ title, subtitle, score }) => (
              <Indicator title={title} subtitle={subtitle} score={score} />
            ))}
          </Box>
          <Box
            sx={{
              flex: '1 1 auto',
              justifySelf: 'stretch',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            {indicators.slice(2).map(({ title, subtitle, score }) => (
              <Indicator title={title} subtitle={subtitle} score={score} />
            ))}
          </Box>
        </Card>

        <Card sx={{ mt: 1, p: '1em', flexDirection: 'column', flex: '0 0 auto' }}>
          <Typography sx={{ fontWeight: 800, mb: '0.5em' }}>{activity.label}</Typography>
          {imageUrl && (
            <img
              style={{
                marginBottom: '0.5em',
                borderRadius: '0.5em',
                flex: '1 1 auto',
                justifySelf: 'flex-end',
                height: 'auto',
                width: '24em',
              }}
              alt=""
              src={imageUrl}
            />
          )}

          <Box sx={{ flexDirection: 'column', justifyContent: 'space-around' }}>
            <Box sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 800, fontSize: '0.8em ' }}>Start</Typography>
              <Typography sx={{ fontSize: '0.8em ' }}>{dateText}</Typography>
            </Box>
            <Box sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 800, fontSize: '0.8em ' }}>Planned distance</Typography>
              <Typography sx={{ fontSize: '0.8em ' }}>{getFormattedDistance(activity.distance)}</Typography>
            </Box>
            <Box sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 800, fontSize: '0.8em ' }}>Planned duration</Typography>
              <Typography sx={{ fontSize: '0.8em ' }}>{`${durationText} h`}</Typography>
            </Box>
            <Box sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 800, fontSize: '0.8em ' }}>Ride type</Typography>
              <Typography sx={{ fontSize: '0.8em ' }}>{rideType}</Typography>
            </Box>
            <Box sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 800, fontSize: '0.8em ' }}>Intensity</Typography>
              <Typography sx={{ fontSize: '0.8em ' }}>{getIntensityText()}</Typography>
            </Box>
          </Box>
        </Card>
      </Box>
    </Modal>
  );
};

export default ActivityModal;
