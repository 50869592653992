import React, { useCallback, useContext, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

const ModalContext = React.createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const autoHideDuration = 5000;
  const [message, setMessage] = useState();
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const updateModal = useCallback(message => {
    setMessage(message);
    setOpen(true);
  }, []);

  const modal = (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
      <Alert onClose={handleClose} severity={message?.severity} sx={{ width: '100%' }}>
        {message?.text}
      </Alert>
    </Snackbar>
  );

  return (
    <ModalContext.Provider value={updateModal}>
      {children}
      {modal}
    </ModalContext.Provider>
  );
};
