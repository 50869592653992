import React from 'react';
import { Avatar, Box, Card, CardActionArea, Typography } from '@mui/material';
import RestaurantIcon from '@mui/icons-material/Restaurant';

import { getCaloriesFromNutrients, nutrientSum } from '../helpers/food';
import { translate } from '../helpers/localization';
import * as Theme from '../style/themes';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const MealCard = props => {
  const { meal, onClick } = props;
  if (!meal) {
    return null;
  }
  const label = translate(`meals.${meal.type}`);

  const caloriesActual = meal.products?.length ? Math.round(nutrientSum(meal.products, 'calories')) : 0;
  const caloriesNeeded = Math.round(getCaloriesFromNutrients(meal.nutrientsNeeded));

  const targetReached = caloriesActual >= caloriesNeeded * 0.9;

  return (
    <Card sx={{ my: 1, flex: '0 0 auto' }}>
      <CardActionArea onClick={onClick} sx={{ flexDirection: 'column', alignItems: 'stretch' }}>
        <Box sx={{ px: '2em', py: '1.5em', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ alignItems: 'center', flex: '0 0 22em' }}>
            <Avatar sx={{ backgroundColor: Theme.colorBlueGray, height: '1.6em', width: '1.6em', flex: '0 0 auto' }}>
              <RestaurantIcon sx={{ fontSize: '1em' }} />
            </Avatar>
            <Typography sx={{ pl: 2, flex: '0 0 4em', textAlign: 'center' }}>{meal.time.substring(0, 5)}</Typography>
            <Typography sx={{ fontWeight: 800, px: '1em', flex: '0 0 14em' }}>{label}</Typography>

            <Box sx={{ flex: '0 0 16em', alignItems: 'center' }}>
              <Typography sx={{ flex: '0 0 3em', px: 1, fontWeight: 700, textAlign: 'end' }} color={!targetReached ? 'warning.main' : null}>
                {Math.round(caloriesActual)}
              </Typography>
              <Typography sx={{ flex: '0 0 3em', fontWeight: 700, textAlign: 'start' }}>{`/ ${Math.round(caloriesNeeded)}`}</Typography>
              <Typography sx={{ flex: '0 0 7em', ml: 0.5, fontWeight: 700, color: Theme.colorBlueGray }}>kcal</Typography>
            </Box>
          </Box>
          <ChevronRightIcon sx={{ ml: 1, mr: -1, color: Theme.colorBlueGray }} />
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default MealCard;
