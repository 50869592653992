import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Modal = ({ sx = {}, maxWidth = 'md', open, setOpen, title, className, children }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BootstrapDialog
      fullWidth
      maxWidth={maxWidth}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      sx={{ flexDirection: 'column' }}
      className={className}
    >
      {title && (
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </BootstrapDialogTitle>
      )}
      <DialogContent dividers sx={{ flexDirection: 'column', ...sx }}>
        {children}
      </DialogContent>
    </BootstrapDialog>
  );
};

export default Modal;
