import { useEffect } from 'react';
import smartlookClient from 'smartlook-client';
import Urls from '../constants/urls';
import SMARTLOOK_EVENTS from '../constants/smartlookEvents';

export const useSmartlook = () => {
  useEffect(() => {
    if (Urls().ENV === 'production') {
      smartlookClient.init(Urls().SMARTLOOK_KEY);
      window.smartlook('consentIP', 'consent given');
      window.smartlook('consentAPI', 'consent given');
    }
  }, []);
};

export const trackSmartlookEvent = (event, props) => {
  if (typeof props !== 'object' && !!props) {
    console.error('trackCustomEvent props should be an object!');
    return;
  }

  if (Urls().ENV === 'production' && SMARTLOOK_EVENTS[event]) {
    smartlookClient.track(SMARTLOOK_EVENTS[event], props);
  }
};
