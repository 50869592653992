import React from 'react';
import BarChart from 'components/common/BarChart';
import useElementSize from 'hooks/useElementSize';
import { Box } from '@mui/material';

const WorkoutChart = ({ workout }) => {
  const { ref, width } = useElementSize();

  const workoutData = JSON.parse(workout.details);

  if (!workoutData.Structure) {
    return null;
  }

  const workoutChartData = workoutData.Structure.reduce((result, segment) => {
    if (segment.Type === 'Repetition') {
      return [...result, ...Array(segment.Length.Value).fill(segment.Steps).flat()];
    }
    return [...result, segment];
  }, []).map(step => ({
    value: step.IntensityTarget.Value,
    length: step.Length.Value,
  }));

  return (
    <Box ref={ref}>
      <BarChart height={300} width={width} data={workoutChartData} />
    </Box>
  );
};

export default WorkoutChart;
