import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Card } from '@mui/material';

import * as Theme from '../style/themes';
import useAxios from '../helpers/api';

import { useLogIn } from '../helpers/UserContext';

const Copyright = props => (
  <Typography variant="body2" color="text.secondary" align="center" {...props}>
    {'Copyright © '}
    <Link color="inherit" href="https://eatmyride.com/">
      EatMyRide B.V.
    </Link>{' '}
    {new Date().getFullYear()}.
  </Typography>
);

const SignIn = () => {
  const logInUser = useLogIn();
  const [errorMessage, setError] = useState();
  const api = useAxios();

  const handleSubmit = async event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const credentials = {
      email: data.get('email'),
      password: data.get('password'),
      remember: !!data.get('remember'),
    };
    setError();
    api.user
      .login(credentials.email, credentials.password)
      .then(response => {
        if (!response.data?.coach) {
          setError('Unable to login: not a coach');
        } else {
          logInUser(response.data);
        }
      })
      .catch(error => {
        let errorMessage;
        switch (error.response?.status) {
          case 403:
            errorMessage = 'Incorrect Email or Password';
            break;
          default:
            errorMessage = 'An Error Occurred';
        }
        setError(errorMessage);
        logInUser(null);
      });
  };

  return (
    <>
      <Container component="main" maxWidth="xs" sx={Theme.centerHV}>
        <CssBaseline />
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '24px',
            boxShadow: 4,
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main', flex: '0 0 auto' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
            <TextField margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />
            {errorMessage && <Box sx={{ display: 'flex', justifyContent: 'center', color: 'red' }}>{errorMessage}</Box>}
            <FormControlLabel control={<Checkbox name="remember" value="remember" color="primary" />} label="Remember me" />
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button
                type="submit"
                // fullWidth
                variant="contained"
              >
                Sign In
              </Button>
            </Box>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Card>
      </Container>
      <Copyright sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', bottom: 0, mb: 2 }} />
    </>
  );
};

export default SignIn;
