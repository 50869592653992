import React from 'react';
import { Box, Card, Typography, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import Modal from './common/Modal';
import Indicator from './Indicator';
import { translate } from '../helpers/localization';

import { getIndicators } from '../helpers/indicators';
import { INDICATOR } from '../constants/constants';

const getQuantity = product =>
  product.ingredientsQtyUnit === 'piece'
    ? `${product.amount} x`
    : product.ingredientsQtyUnit === 'gram'
    ? `${product.amount} g`
    : product.ingredientsQtyUnit === 'ml'
    ? `${product.amount} ml`
    : 'Unknown';

const aggregatedMealProducts = products =>
  Object.entries(
    products.reduce((aggregator, item) => {
      const amount = (aggregator[item.id]?.amount || 0) + item.amount;
      aggregator[item.id] = { ...item, amount };
      return aggregator;
    }, {}),
  ).map(([, v]) => v);

const ProductList = ({ products }) => (
  <List sx={{ flexDirection: 'column' }}>
    {products.map(({ label, description, calories, carbohydrates: carbs, fat, protein, ...product }, i) => (
      <ListItem key={i}>
        <ListItemAvatar sx={{ flex: 0, minWidth: 'unset', marginRight: '12px', alignItems: 'center' }}>
          <Avatar sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '0.8em' }}>{getQuantity(product)}</Avatar>
        </ListItemAvatar>
        <ListItemText sx={{ flexDirection: 'column' }} primary={label} secondary={description} />
        <ListItemText
          sx={{ justifyContent: 'flex-end', flex: '0 0 4em' }}
          primary={`${Math.round((calories * product.amount) / product.ingredientsQty)} kcal`}
        />
      </ListItem>
    ))}
  </List>
);

const MealModal = ({ meal, athlete, setOpen }) => {
  const title = `${translate(`meals.${meal.type}`)} - ${meal.time.substring(0, 5)}`;
  const products = (meal.products && aggregatedMealProducts(meal.products)) || [];

  const enabledIndicators = [INDICATOR.KCAL, INDICATOR.CARBOHYDRATES, INDICATOR.PROTEINS, INDICATOR.FATS];

  const indicators = getIndicators(enabledIndicators, products, meal.nutrientsNeeded, null, false, athlete.measurementSystem);

  return (
    <Modal sx={{ flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }} title={title} open={!!meal} setOpen={setOpen}>
      <Card sx={{ p: '1em', flex: '0 0 25em' }}>{products?.length > 0 ? <ProductList products={products} /> : 'No products added.'}</Card>
      <Card sx={{ flex: '0 0 26em', p: '1em', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box sx={{ flex: '0 0 auto', flexDirection: 'column' }}>
          <Typography sx={{ alignSelf: 'flex-start', fontWeight: 500 }}>Summary</Typography>

          <Box sx={{ flex: '1 1 auto', justifySelf: 'stretch', justifyContent: 'space-around', alignItems: 'center' }}>
            {indicators.slice(0, 2).map(({ title, subtitle, score }) => (
              <Indicator title={title} subtitle={subtitle} score={score} />
            ))}
          </Box>
          <Box sx={{ flex: '1 1 auto', justifySelf: 'stretch', justifyContent: 'space-around', alignItems: 'center' }}>
            {indicators.slice(2).map(({ title, subtitle, score }) => (
              <Indicator title={title} subtitle={subtitle} score={score} />
            ))}
          </Box>
        </Box>
      </Card>
    </Modal>
  );
};

export default MealModal;
