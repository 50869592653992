import {
  CARB_TO_CAL_MULTIPLIER,
  FAT_TO_CAL_MULTIPLIER,
  MAX_GRAM_PER_HOUR,
  MAX_OXIDATION_RATE,
  MAX_PRODUCT_SCORE,
  MILLIGRAM_TO_GRAMS,
  MIN_GRAM_PER_HOUR,
  MIN_OXIDATION_RATE,
  PROTEIN_TO_CAL_MULTIPLIER,
  SECONDS_TO_HOURS,
} from '../constants/constants';

// Converts different kind of nutrients to calories
export const getCaloriesFromNutrients = (nutrients, type = 'all') => {
  if (!nutrients) {
    return 0;
  }
  switch (type) {
    case 'carbohydrates':
      return CARB_TO_CAL_MULTIPLIER * nutrients.carbohydrates;
    case 'proteins':
      return CARB_TO_CAL_MULTIPLIER * nutrients.proteins;
    case 'fats':
      return CARB_TO_CAL_MULTIPLIER * nutrients.fats;
    case 'all':
      return (
        (CARB_TO_CAL_MULTIPLIER * nutrients.carbohydrates + PROTEIN_TO_CAL_MULTIPLIER * nutrients.proteins + FAT_TO_CAL_MULTIPLIER * nutrients.fats) /
        MILLIGRAM_TO_GRAMS
      );
    default:
      return 0;
  }
};

// Returns amount of calories needed for an activity
export const getCaloriesNeeded = activity => {
  if (!activity) {
    return 0;
  }
  const carbohydrateGramsPerHour = getCarbohydrateGramsPerHour(activity);
  const caloriesNeeded = carbohydrateGramsPerHour * (activity.duration / SECONDS_TO_HOURS) * CARB_TO_CAL_MULTIPLIER;
  return caloriesNeeded;
};

// Returns carbs per hour for an activity
export const getCarbohydrateGramsPerHour = activity => {
  const RECOVERY_MAX = 45;
  // const INTERVAL_MIN = 30;
  const RACE_MIN = 60;
  const GENERAL_MAX = 90;
  // const GENERAL_MIN = 18;
  if (!activity) {
    return 0;
  }
  let carbohydrateGramsPerHour = parseInt(activity.carbohydrateGramsPerHour);
  if (!isNaN(carbohydrateGramsPerHour)) {
    return carbohydrateGramsPerHour;
  }
  carbohydrateGramsPerHour =
    (activity.caloriesThreshold + activity.caloriesNeeded - activity.caloriesStart) / (activity.duration / SECONDS_TO_HOURS) / CARB_TO_CAL_MULTIPLIER;

  const isIntense = ['race', 'interval'].includes(activity.rideType);

  carbohydrateGramsPerHour = Math.max(carbohydrateGramsPerHour, Math.min(activity.duration / 60 / (isIntense ? 3 : 4), isIntense ? RACE_MIN : RECOVERY_MAX));

  return Math.min(carbohydrateGramsPerHour, GENERAL_MAX);
};

// Returns a rating for a product based on it nutrients
const getProductRating = (product, gramPerHour) => {
  let score = MAX_PRODUCT_SCORE;
  const warnings = [];

  const perMinutePenalty = Math.min(
    Math.max(
      ((gramPerHour - MIN_GRAM_PER_HOUR) / (MAX_GRAM_PER_HOUR - MIN_GRAM_PER_HOUR)) * (MAX_OXIDATION_RATE - MIN_OXIDATION_RATE) + MIN_OXIDATION_RATE,
      MIN_OXIDATION_RATE,
    ),
    MAX_OXIDATION_RATE,
  );

  if (product.per_minute < perMinutePenalty) {
    warnings.push('absorption');
    score--;
  }

  let fatPenalty = 0.1;
  let proteinPenalty = 0.1;
  let fiberPenalty = 0.05;

  if (gramPerHour < 30) {
    fatPenalty = 0.2;
    proteinPenalty = 0.2;
    fiberPenalty = 0.1;
  } else if (gramPerHour < 60) {
    fatPenalty = 0.15;
    proteinPenalty = 0.15;
    fiberPenalty = 0.075;
  }

  if (product.weight > 0) {
    if (product.fat && product.fat / product.weight > fatPenalty) {
      warnings.push('fat');
      score--;
    }
    if (product.protein && product.protein / product.weight > proteinPenalty) {
      warnings.push('protein');
      score--;
    }
    if (product.fiber && product.fiber / product.weight > fiberPenalty) {
      warnings.push('fibers');
      score--;
    }
  }
  return { score, warnings };
};

// Returns the average rating for all products in a foodList
export const getFoodListRating = (foodList, gramPerHour, scheduledOnly) => {
  // const ratings = [];

  const sum = foodList.reduce(
    (sum, f) => {
      if (!scheduledOnly || f.time > -1) {
        const rating = getProductRating(f, gramPerHour);
        return [sum[0] + rating.score, sum[1] + 1];
      }
      return sum;
    },
    [0, 0],
  );
  return sum[1] === 0 ? 0 : Math.round(sum[0] / sum[1]);
};

// get a grouped list of products from a foodList
/* const getProductsFromFoodList = foodList => {
  const FoodCardProducts = _(foodList)
    .map(f => f.product)
    .groupBy('id')
    .map(items => {
      const product = items[0];
      if (!product) {
        return null;
      }

      product.count = items.length;
      return product;
    })
    .value();

  return FoodCardProducts;
}; */

const getNutrientsPerServing = item => {
  let { fibers, fat, carbohydrates, calories, protein, sugars, salt, servingQty, ingredientsQty, ingredientsQtyUnit } = item;

  if (ingredientsQtyUnit === 'gram') {
    // convert to per serving
    const multiplier = servingQty / ingredientsQty;
    fibers = Math.round(multiplier * fibers);
    fat = Math.round(multiplier * fat);
    carbohydrates = Math.round(multiplier * carbohydrates);
    calories = Math.round(multiplier * calories);
    protein = Math.round(multiplier * protein);
    sugars = Math.round(multiplier * sugars);
    salt = Math.round(multiplier * salt);
  }

  return { fibers, fat, carbohydrates, calories, protein, sugars, salt };
};

const countDrinkNutrients = (f, nutrient) => {
  if (!f) {
    return 0;
  }
  // todo: use getNutrientsPerServing after implementing drinks in it
  const total = (f.product[nutrient] * f.gram) / f.product.ingredientsQty; // use drink powders grams
  return total;
};

const countFoodNutrients = (f, nutrient) => {
  if (!f) {
    return 0;
  }
  const nutrientsPerServing = getNutrientsPerServing(f.product || f);
  const total =
    nutrientsPerServing[nutrient] *
    (f.amount
      ? f.amount / (f.product?.servingQty || f.servingQty) // multiply by amount if available
      : 1); // default to 1 serving
  return total;
};

// Return sum of specific nutrient in foodList
export const nutrientSum = (foodList, nutrient) => {
  const result = foodList.reduce(
    (sum, f) =>
      sum +
      (f.gram && isDrink(f.product) // (bidons)
        ? countDrinkNutrients(f, nutrient)
        : countFoodNutrients(f, nutrient)),
    0,
  );
  return result;
};

// Return sum of ml drinks in foodList
export const fluidsSum = foodList => foodList.reduce((sum, f) => sum + (f.drink ? f.ml : 0), 0);

// Returns weither a product is a drink
const isDrink = product => product.tags?.toLowerCase().includes('drink');
