import React from 'react';
import PropTypes from 'prop-types';

class ScrollDrag extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.innerRef = React.createRef();
    this.state = {
      addingContent: null,
      outerBounds: null,
      innerBounds: null,
      children: [],
    };
    this.clientX = 0;
  }

  componentDidMount() {
    this.setState({
      outerBounds: this.ref.current.getBoundingClientRect(),
      innerBounds: this.innerRef.current.getBoundingClientRect(),
    });
  }

  static getDerivedStateFromProps(props, state) {
    let { children } = state;
    if (props.children !== state.children) {
      children = props.children;
    }
    return { children };
  }

  componentDidUpdate(prev) {
    if (this.state.addingContent && prev.children !== this.props.children) {
      const newInnerBounds = this.innerRef.current.getBoundingClientRect();
      const newOuterBounds = this.ref.current.getBoundingClientRect();
      const widthDiff = (6.0 * newInnerBounds.width) / this.props.children.length;

      this.ref.current.scrollLeft += (this.state.addingContent === 'right' ? -1 : 1) * widthDiff;

      this.setState({ addingContent: false, innerBounds: newInnerBounds, outerBounds: newOuterBounds });
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.children !== this.props.children;
  }

  onMouseDown = e => {
    this.isScrolling = true;
    this.clientX = e.clientX;
  };

  onMouseUp = () => {
    this.isScrolling = false;
    if (this.props.onEndScrolling) {
      const visibleIndex = Math.round(this.ref.current.scrollLeft / (this.state.innerBounds.width / this.props.children.length));

      this.props.onEndScrolling(visibleIndex);
      this.ref.current.scrollLeft = 0;
    }
  };

  onMouseMove = e => {
    const { innerBounds } = this.state;
    const edgeAllowance = (2.0 * innerBounds.width) / this.props.children.length;
    if (this.isScrolling) {
      this.ref.current.scrollLeft += -e.clientX + this.clientX;
      // this.setState({ clientX: e.clientX });
      this.clientX = e.clientX;
      if (!this.state.addingContent) {
        if (this.ref.current.scrollLeft < edgeAllowance) {
          this.setState({
            addingContent: 'left',
          });
          this.props.onAddLeft();
        } else if (this.ref.current.scrollLeft > this.state.innerBounds.width - this.state.outerBounds.width - edgeAllowance) {
          this.setState({
            addingContent: 'right',
          });
          this.props.onAddRight();
        }
      }
    }
  };

  render() {
    const { rootClass } = this.props;

    const flexContainer = {
      flexDirection: 'row',
      overflow: 'hidden',
      margin: '0.5em',
      width: '33em',
    };

    return (
      <div
        ref={this.ref}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onMouseMove={this.onMouseMove}
        onTouchStart={e => this.onMouseDown(e.touches[0])}
        onTouchMove={e => this.onMouseMove(e.touches[0])}
        onTouchEnd={e => this.onMouseUp(e.touches[0])}
        className={rootClass}
        style={flexContainer}
      >
        <div ref={this.innerRef} style={{ flexDirection: 'row' }}>
          {React.Children.map(this.state.children, child => React.Children.only(child))}
        </div>
      </div>
    );
  }
}

ScrollDrag.defaultProps = {
  rootClass: '',
};

ScrollDrag.propTypes = {
  rootClass: PropTypes.string,
  children: PropTypes.array,
};

export default ScrollDrag;
