import { useMemo } from 'react';
import axios from 'axios';
import moment from 'moment';

import URLS from '../constants/urls';
import DeviceInfo from '../constants/constants';

import { useUser } from './UserContext';

export default function useAxios() {
  const userObject = useUser();

  const baseUrl = URLS().API;

  const apiCalls = useMemo(() => {
    const baseURL = baseUrl + (userObject?.role === 'coach' ? 'coach' : '');

    const isCoach = userObject?.role === 'coach';
    const api = axios.create({ baseURL });
    api.interceptors.request.use(
      async config => {
        config.headers = {
          'accept-version': URLS().API_VERSION,
          'app-build': DeviceInfo.getBuildNumber(),
        };
        if (userObject?.token) {
          config.headers.Authorization = `Bearer ${userObject.token}`;
        }
        return config;
      },
      error => new Promise((resolve, reject) => reject(error)),
    );

    const user = {
      register: (email, password, resend) => api.post('auth/emailregister', { email, password, resend }),
      login: (email, password) => api.post('auth/login', { email, password }),
      resetPasswordRequest: email => api.post('auth/forgotpasswordrequest', { email }),
      erasePermanently: password => api.post('account/erase_permanently', { password }),
      listSubscriptions: purchase => api.get('account/subscription/list', purchase),
      addSubscription: purchase => api.post('account/subscription', purchase),
      restoreSubscription: () => api.post('account/subscription/restore'),
      getSubscriptionCancelUrl: platformOS => api.get(`account/subscription/cancel_url/${platformOS}`),
      activeSubscription: () => api.get('account/subscription'),
      get: () => api.get('account/user'),
      put: item => api.put('account/user', item),
      invite: credentials => api.put('invite', credentials),
    };

    const profile = {
      get: () => api.get('account/profile'),
      put: item => api.put('account/profile', item),
      set: () => api.post('account/updateprofile'),
    };

    const activity = {
      get: id => api.get(`activities/${id}`),
      getAll: (userId, from, to) => api.get(`${isCoach ? `${userId}/` : ''}activities/list/${from}/${to}`),
      post: item => api.post('activities', item),
      put: item =>
        api.put(`activities/${item.id}`, {
          ...item,
          _localDate: moment(item.date).toISOString(true), // add client timezone info
        }),
      delete: id => api.delete(`activities/${id}`),
      saveLiveData: (id, liveData) => api.post(`activities/${id}/live_data`, liveData),
      start: id => api.post(`activities/${id}/start`),
      pause: id => api.post(`activities/${id}/pause`),
      stop: id => api.post(`activities/${id}/stop`),
    };

    const evaluatedActivity = {
      post: (completedActivityId, activityId, rideType, date) =>
        api.post('activities/evaluated', {
          completedActivityId,
          activityId,
          rideType,
          _localDate: moment(date).toISOString(true), // add client timezone info
        }),
      get: id => api.get(`activities/evaluated/${id}`),
      delete: id => api.delete(`activities/evaluated/${id}`),
    };
    const foodplan = {
      get: (userId, activityId) => api.get(`${isCoach ? `${userId}/` : ''}foodplan/${activityId}`),
      save: (activityId, item) => api.post(`foodplan/${activityId}`, item),
    };

    const day = {
      // get: activityId => api.get('foodplan/' + activityId),
      get: (userId, date) => api.get(`${isCoach ? `${userId}/` : ''}days/${date}`),
      getMeals: (userId, date) => api.get(`${isCoach ? `${userId}/` : ''}days/${date}/meals`),
      getActivities: (userId, date) => api.get(`${isCoach ? `${userId}/` : ''}days/${date}/activities`),
      saveMeals: (meals, date) => api.post(`days/${date}/meals`, meals),
    };

    /* const meal = {
      //get: id => api.get('meals/' + id),
      //post: meals => api.post('meals', meals),
      // delete: id => api.delete('meals/' + id),
      /*product: {  not sure if we should delete product by id, maybe its better so save meal with products
        post: (mealId,item) => api.post('meals/' + mealId+'/products', item),
        delete: (mealId,productId)  => api.delete('meals/' + mealId+'/products', productId),
      }*
    }; */

    const routes = {
      get: id => api.get(`routes/${id}`),
      post: item => api.post('routes', item),
      put: item => api.put(`routes/${item.id}`, item),
      search: (q, source, params) => api.post('routes/search', { q, source, params }),
      map: id =>
        api.get(`routes/map/${id}`, {
          responseType: 'arraybuffer',
        }),
      uploadRoute: (formData, onUploadProgress) => api.post('routes/upload', formData, { onUploadProgress }),
    };
    // imageUrl={"http://0.0.0.0:3001/api/routes/map/" + item.id}
    const images = {
      getFromRoute: (userId, id) =>
        api.get(`${isCoach ? `${userId}/` : ''}routes/map/${id}`, {
          responseType: 'blob',
        }),
    };
    const imports = {
      getStatus: () => api.get('imports/status'),
    };

    const trackers = {
      getConnected: () => api.get('trackers/list'),
      put: tracker => api.put(`trackers/${tracker.provider}`, tracker),
      delete: name => api.delete(`trackers/${name}`),
      getAvailable: () => api.get('trackers/available'),
      strava: {
        getToken: code => api.post('trackers/strava/request_token', { code }),
      },
      garmin: {
        getRequestToken: () => api.get('trackers/garmin/request_token'),
        createAccessToken: params => api.post('trackers/garmin/access_token', params),
      },
      wahoo: {
        getRequestToken: () => api.get('trackers/wahoo/request_token'),
        createAccessToken: params => api.post('trackers/wahoo/access_token', params),
      },
      komoot: {
        getToken: code => api.post('trackers/komoot/request_token', { code }),
      },
      rideWithGps: {
        connect: (email, password) => api.post('trackers/rwgps/connect', { email, password }),
      },
    };

    const product = {
      get: id => api.get(`products/${id}`),
      post: item => api.post('products', item),
      put: item => api.put(`products/${item.id}`, item),
      delete: id => api.delete(`products/${id}`),
      exists: code => api.get(`products/exists/${code}`),
      getAll: () => api.get('products/list'),
      search: (q, filter) => api.post('products/search', { q, filter }),
      getRegularFoodsForUser: (userId, type) => api.get(`products/regular/${userId}/${type}`),
    };

    const log = {
      put: item => api.put('log', item),
    };

    return {
      user,
      profile,
      activity,
      evaluatedActivity,
      foodplan,
      day,
      routes,
      trackers,
      product,
      imports,
      images,
      log,
    };
  }, [baseUrl, userObject?.role, userObject?.token]);

  return apiCalls;
}
