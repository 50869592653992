const URL_SCHEME_PREFIX = 'eatmyride';
const URL_SCHEME_HOST = 'beta-platform.nl';
const API_VERSION = '1.01';

const STRAVA_CLIENT_ID = 46549; // production
const STRAVA_CLIENT_ID_LOCAL = 47734; // local
const STRAVA_CLIENT_ID_JORAM = 40455; // staging
const STRAVA_TOKEN_PATH = 'strava_token_exchange';
const STRAVA_CALL_BACK_URL_SCHEME = `${URL_SCHEME_PREFIX}://${URL_SCHEME_HOST}/${STRAVA_TOKEN_PATH}`;

const GARMIN_TOKEN_PATH = 'garmin_token_exchange';
const GARMIN_CALL_BACK_URL_SCHEME = `${URL_SCHEME_PREFIX}://${URL_SCHEME_HOST}/${GARMIN_TOKEN_PATH}`;

const WAHOO_TOKEN_PATH = 'wahoo_token_exchange';
const WAHOO_CALL_BACK_URL_SCHEME = `${URL_SCHEME_PREFIX}://${URL_SCHEME_HOST}/${WAHOO_TOKEN_PATH}`;

const KOMOOT_TOKEN_PATH = 'komoot_token_exchange';
const KOMOOT_CLIENT_ID = 'eat-my-ride-qnht3l';
const KOMOOT_CALL_BACK_URL_SCHEME = `${URL_SCHEME_PREFIX}://${URL_SCHEME_HOST}/${KOMOOT_TOKEN_PATH}`;
const KOMOOT_INITIAL_URI = `https://account.komoot.com/authorize?client_id=${KOMOOT_CLIENT_ID}&response_type=code&state=1234567abcd&redirect_uri=${KOMOOT_CALL_BACK_URL_SCHEME}&scope=tour-upload,profile`;

const SMARTLOOK_KEY = 'dfccdad563789acd511cb20b13c44216f40bf8ef';

const production = {
  ENV: 'production',
  API: 'https://www.beta-platform.nl/api/',
  API_VERSION,
  PRODUCT_IMAGE: 'https://www.beta-platform.nl/assets/products/',
  STRAVA_CALL_BACK_URL_SCHEME,
  STRAVA_INITIAL_URI: `https://www.strava.com/oauth/authorize?response_type=code&redirect_uri=${STRAVA_CALL_BACK_URL_SCHEME}&scope=activity%3Aread_all&client_id=${STRAVA_CLIENT_ID}`,
  STRAVA_TOKEN_PATH,
  GARMIN_CALL_BACK_URL_SCHEME,
  GARMIN_TOKEN_PATH,
  WAHOO_CALL_BACK_URL_SCHEME,
  WAHOO_TOKEN_PATH,
  KOMOOT_CALL_BACK_URL_SCHEME,
  KOMOOT_INITIAL_URI,
  KOMOOT_TOKEN_PATH,
  SMARTLOOK_KEY,
};

const staging = {
  ENV: 'staging',
  API: 'https://staging-www.beta-platform.nl/api/',
  API_VERSION,
  PRODUCT_IMAGE: 'https://staging-www.beta-platform.nl/assets/products/',
  STRAVA_CALL_BACK_URL_SCHEME,
  STRAVA_INITIAL_URI: `https://www.strava.com/oauth/authorize?response_type=code&redirect_uri=${STRAVA_CALL_BACK_URL_SCHEME}&scope=activity%3Aread_all&client_id=${STRAVA_CLIENT_ID_JORAM}`,
  STRAVA_TOKEN_PATH,
  GARMIN_CALL_BACK_URL_SCHEME,
  GARMIN_TOKEN_PATH,
  WAHOO_CALL_BACK_URL_SCHEME,
  WAHOO_TOKEN_PATH,
  KOMOOT_CALL_BACK_URL_SCHEME,
  KOMOOT_INITIAL_URI,
  KOMOOT_TOKEN_PATH,
  SMARTLOOK_KEY,
};

const LOCAL_IP = 'localhost';

const local = {
  ENV: 'local',
  API: `http://${LOCAL_IP}:3001/api/`, // use real local ip like 192.168.1.13 for android
  API_VERSION,
  PRODUCT_IMAGE: `http://${LOCAL_IP}:3001/assets/products/`,
  STRAVA_CALL_BACK_URL_SCHEME,
  STRAVA_INITIAL_URI: `https://www.strava.com/oauth/authorize?response_type=code&redirect_uri=${STRAVA_CALL_BACK_URL_SCHEME}&scope=activity%3Aread_all&client_id=${STRAVA_CLIENT_ID_LOCAL}`,
  STRAVA_TOKEN_PATH,
  GARMIN_CALL_BACK_URL_SCHEME,
  GARMIN_TOKEN_PATH,
  WAHOO_CALL_BACK_URL_SCHEME,
  WAHOO_TOKEN_PATH,
  KOMOOT_CALL_BACK_URL_SCHEME,
  KOMOOT_INITIAL_URI,
  KOMOOT_TOKEN_PATH,
  SMARTLOOK_KEY,
};

const isReleaseBuild = false;
const environments = { production, staging, local };
let activeEnvironment = null;

function getActiveEnvironment() {
  return Object.keys(environments)[activeEnvironment];
}

async function setActiveEnvironmentFromStorage() {
  if (activeEnvironment === null) {
    activeEnvironment = parseInt(localStorage.getItem('activeEnvironment'), 10) || 0;
  }
}

function toggleActiveEnvironment() {
  if (!isReleaseBuild) {
    activeEnvironment++;
    if (activeEnvironment >= Object.keys(environments).length) {
      activeEnvironment = 0;
    }
    localStorage.setItem('activeEnvironment', activeEnvironment.toString());
  }
}

setActiveEnvironmentFromStorage();

export { environments, getActiveEnvironment, toggleActiveEnvironment, isReleaseBuild };

const Env = () => {
  if (isReleaseBuild || !activeEnvironment) {
    return production;
  }
  return environments[Object.keys(environments)[activeEnvironment]];
};

export default Env;
