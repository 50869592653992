import React, { useState } from 'react';
import { Box, Tabs, Tab, CssBaseline, Button } from '@mui/material';

import SignIn from './SignIn';
import * as Theme from '../style/themes';
import { useUser } from '../helpers/UserContext';
import AthleteContainer from './Athletes/AthleteContainer';
import Settings from './Settings';
import { translate } from '../helpers/localization';
import { getActiveEnvironment, isReleaseBuild, toggleActiveEnvironment } from '../constants/urls';

const imageURL = './bg.jpg';

const DefaultPage = () => (
  <>
    <CssBaseline />
    <Box
      sx={{
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      Coming Soon
    </Box>
  </>
);

const getPage = (user, tab, showMenu) => {
  switch (tab) {
    case 'athletes':
      return <AthleteContainer isCoach={user?.role === 'coach'} showLogOut={!showMenu} />;
    case 'settings':
      return <Settings />;
    default:
      return <DefaultPage />;
  }
};

const EMRLogoBox = ({ style }) => (
  <Box sx={{ ...style, flex: '0 0 5em', justifyContent: 'center' }}>
    <img alt="EatMyRide Logo" src="https://eatmyride.com/wp-content/uploads/2020/09/Logo.svg" style={{ width: '12em', height: 'auto' }} />
  </Box>
);

const DashboardContainer = () => {
  const user = useUser();
  const [currentTab, changeTab] = useState('athletes');

  const [currentEnvironment, setCurrentEnvironment] = useState(getActiveEnvironment());

  const showMenu = false;

  const handleChange = (_e, value) => {
    changeTab(value);
  };

  const toggleEnvironment = () => {
    toggleActiveEnvironment();

    setCurrentEnvironment(getActiveEnvironment());
  };

  const style = {
    alignItems: 'flex-start',
    borderRadius: '16px',
    mx: 1,
    textTransform: 'none',
  };
  const selectedStyle = {
    ...style,
    color: Theme.colorBlue,
    backgroundColor: Theme.colorGrayLight,
    borderRadius: '10px',
    mx: 1,
  };
  const tabs = ['athletes', 'plans', 'meals', 'settings'].map(value => ({
    value,
    label: translate(`menu.${value}`),
  }));

  const page = (
    <Box
      sx={{
        flex: 1,
        flexDirection: 'column',
        boxShadow: 0,
        zIndex: 0,
        width: '100%',
      }}
    >
      {getPage(user, currentTab, showMenu)}
    </Box>
  );

  return !user || !['coach', 'premium'].includes(user.role) ? (
    <Box sx={{ ...Theme.fullscreen, ...Theme.bgImage(imageURL) }} style={{ flexDirection: 'column' }}>
      <EMRLogoBox
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          borderRadius: '2.5em',
          padding: '1em',
          margin: '1em',
          alignSelf: 'flex-start',
        }}
      />
      {!isReleaseBuild && (
        <Button
          style={{
            backgroundColor: Theme.colorBlue,
            color: 'white',
            width: '250px',
            alignSelf: 'center',
          }}
          onClick={toggleEnvironment}
        >
          Toggle environment ({currentEnvironment})
        </Button>
      )}
      <SignIn />
    </Box>
  ) : !showMenu ? (
    <Box
      sx={{
        ...Theme.fullscreen,
        flexDirection: 'column',
        backgroundColor: '#f8f8f8',
        alignItems: 'center',
      }}
    >
      <EMRLogoBox
        style={{
          backgroundColor: '#f8f8f8',
          width: '100%',
          maxWidth: `${Theme.viewWidth}em`,
        }}
      />
      {page}
    </Box>
  ) : (
    <Box sx={{ ...Theme.fullscreen, flexDirection: 'row' }}>
      <Box
        sx={{
          backgroundColor: 'white',
          flexDirection: 'column',
          zIndex: 2,
          flex: '0 0 16em',
          boxShadow: 4,
        }}
      >
        <EMRLogoBox />
        <Tabs value={currentTab} orientation="vertical" onChange={handleChange} indicatorColor={null} sx={{ mt: 1 }}>
          {tabs.map((t, i) => (
            <Tab key={i} value={t.value} label={t.label} sx={t.value === currentTab ? selectedStyle : style} />
          ))}
        </Tabs>
      </Box>
      {page}
    </Box>
  );
};

export default DashboardContainer;
