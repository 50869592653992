import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import moment from 'moment';

import * as Theme from '../style/themes';

import ScrollDrag from './ScrollDrag';

const Calendar = ({ currentDate, setCurrentDate, onSetStart, markedDates }) => {
  const [start, setStart] = useState(-6);

  const selectDate = selection => {
    setCurrentDate(selection);
    setStart(selection - 12);
  };

  useEffect(() => {
    onSetStart(start);
  }, [start, onSetStart]);

  useEffect(() => setStart(currentDate - 3), [currentDate]);

  const items = Array.from({ length: 100 }, (_, i) => {
    const m = moment()
      .add(i + start, 'days')
      .startOf('day');
    const dayOfMonth = m.date();
    const dotColor = markedDates[m.format()];
    const showMonth = dayOfMonth === 1 || i === 0;
    const isCurrentDate = i + start === currentDate;

    return (
      <Box key={i} sx={{ width: '3em', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ fontSize: 10, height: 10, fontWeight: 800, color: Theme.colorBlueGray }}>{showMonth ? m.format('MMM') : ''}</Box>
        <Button
          key={i + start}
          variant={isCurrentDate ? 'contained' : i + start === 0 ? 'contained' : 'text'}
          color="primary"
          sx={{
            minWidth: 32,
            height: 32,
            borderRadius: 4,
            mx: 0.25,
            px: 0,
            my: 0,
            color: i + start === 0 ? Theme.colorWhite : null,
            backgroundColor: i + start === 0 ? Theme.colorBlueGray : null,
          }}
          onClick={() => selectDate(i + start)}
        >
          {`${dayOfMonth}`}
        </Button>
        <Box sx={{ fontSize: '0.5rem', flex: '0 0 1em' }}>{dotColor && <FiberManualRecordIcon color={dotColor} fontSize="0.5em" />}</Box>
      </Box>
    );
  });

  return (
    <ScrollDrag
      onAddLeft={() => setStart(start => start - 6)}
      onAddRight={() => setStart(start => start + 6)}
      onEndScrolling={index => setStart(start => start + index)}
    >
      {items}
    </ScrollDrag>
  );
};

export default Calendar;
