import React from 'react';
import * as Theme from 'style/themes';

const BarChart = ({ data, height: chartHeight, width: chartWidth, boundary = 0.1 }) => {
  const TOP_BOTTOM_GUTTER = chartHeight * boundary;

  const maxBarHeight = data.reduce((maxValue, bar) => (bar.value > maxValue ? bar.value : maxValue), 0);

  const minBarHeight = data.reduce((minValue, bar) => (bar.value < minValue ? bar.value : minValue), chartHeight);

  const totalNumberOfBars = data.reduce((total, bar) => total + bar.length ?? 1, 0);

  let nextX = 0;

  const chartData = data.map(bar => {
    const barWidth = (chartWidth / totalNumberOfBars) * bar.length ?? 1;

    nextX += barWidth;

    return {
      height:
        maxBarHeight === minBarHeight
          ? TOP_BOTTOM_GUTTER
          : // Lowest value is always equal to TOP_BOTTOM_GUTTER
            // Highest value is always equal to chartHeight - TOP_BOTTOM_GUTTER
            // Other values are calculated by calculating ratio between max value and bar value corrected for TOP_BOTTOM_GUTTER
            (chartHeight - 2 * TOP_BOTTOM_GUTTER) * ((bar.value - minBarHeight) / (maxBarHeight - minBarHeight)) + TOP_BOTTOM_GUTTER,
      width: (chartWidth / totalNumberOfBars) * bar.length ?? 1,
      x: nextX - barWidth,
    };
  });

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={chartWidth} height={chartHeight} viewBox={`0 0 ${chartWidth} ${chartHeight}`}>
      <g>
        {chartData.map((bar, index) => (
          <rect
            key={index}
            x={bar.x}
            y={chartHeight - bar.height}
            // The extra width prevents very tiny whitespace between bars
            width={bar.width + 0.0008 * chartWidth}
            height={bar.height}
            fill={Theme.colorBlueGray}
          />
        ))}
      </g>
    </svg>
  );
};

export default BarChart;
