import { translate } from './localization';
import { convertMeasurement } from './measurementConverter';

export const distanceAsText = (distanceInMeters, hasImperialMeasurementSystem) =>
  isNaN(distanceInMeters)
    ? ''
    : `${Math.round(hasImperialMeasurementSystem ? convertMeasurement(distanceInMeters / 1000, 'km/h', 'm/h') : distanceInMeters / 1000)} ${translate(
        `units.${hasImperialMeasurementSystem ? 'mile.short' : 'km.short'}`,
      )}`;

export const durationAsText = duration => {
  const secNum = parseInt(duration, 10); // don't forget the second param
  if (isNaN(secNum)) {
    return '';
  }
  const hours = Math.floor(secNum / 3600);
  let minutes = Math.floor((secNum - hours * 3600) / 60);

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  return `${hours}:${minutes} ${translate('units.hours.short')}`;
};

export const intensityAsText = (activity, intensityType) => {
  if (!activity) {
    return null;
  }
  const { avgPowerRide, avgHeartRate } = activity;

  if (avgPowerRide && intensityType === 'power') {
    return `${Math.round(avgPowerRide)} ${translate('evaluate.intensity_power')}`;
  }
  if (avgHeartRate && intensityType === 'heartrate') {
    return `${avgHeartRate} ${translate('evaluate.intensity_bpm')}`;
  }
  return '';
};
