import React, { useEffect } from 'react';
import clsx from 'clsx';
import { styled } from '@mui/system';
import { useSwitch } from '@mui/core/SwitchUnstyled';

import * as Theme from '../style/themes';

const SwitchRoot = styled('span')(`
  align-self: flex-end;
  display: inline-block;
  position: relative;
  width: 62px;
  height: 32px;
  padding: 0px;
`);

const SwitchInput = styled('input')(`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
  cursor: pointer;
`);

const SwitchThumb = styled('span')(
  ({ theme }) => `
  position: absolute;
  display: block;
  background-color: ${theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main};
  width: 32px;
  height: 32px;
  border-radius: 16px;
  top: 0px;
  left: 0px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);

  &:before {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 16 16"><path fill="${encodeURIComponent(
      '#fff',
    )}" d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/><path fill="${encodeURIComponent(
    '#fff',
  )}" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/></svg>') center center no-repeat;
  }

  &.focusVisible {
    background-color: #79B;
  }

  &.checked {
    transform: translateX(32px);
    
    &:before {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 16 16"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/></svg>');
    }
  }
`,
);

const SwitchThumbAlt = styled('span')(
  () => `
  position: absolute;
  display: block;
  background-color: ${Theme.colorGray};
  width: 32px;
  height: 32px;
  border-radius: 16px;
  top: 0px;
  left: 32px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);

  &:before {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 16 16"><path fill="${encodeURIComponent(
      '#fff',
    )}" d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/></svg>') center center no-repeat;
  }

  &.focusVisible {
    background-color: #79B;
  }

  &.checked {
    transform: translateX(-32px);
    
    &:before {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 16 16"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/><path fill="${encodeURIComponent(
    '#fff',
  )}" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/></svg>');
    }
  }
`,
);

const SwitchTrack = styled('span')(
  ({ theme }) => `
  background-color: ${theme.palette.mode === 'dark' ? '#8796A5' : Theme.colorGray};
  border-radius: 16px;
  width: 100%;
  height: 32px;
  display: block;
`,
);

const MUISwitch = props => {
  const { getInputProps, checked, disabled, focusVisible } = useSwitch(props);

  const onToggle = props.onToggle;

  const stateClasses = {
    checked,
    disabled,
    focusVisible,
  };

  useEffect(() => {
    if (onToggle) {
      onToggle(checked);
    }
  }, [checked, onToggle]);

  return (
    <SwitchRoot className={clsx(stateClasses)}>
      <SwitchTrack>
        <SwitchThumbAlt className={clsx(stateClasses)} />
        <SwitchThumb className={clsx(stateClasses)} />
      </SwitchTrack>
      <SwitchInput {...getInputProps()} aria-label="Time Distance Switch" />
    </SwitchRoot>
  );
};

const UseSwitchesCustom = ({ checked, onToggle }) => {
  const onChange = event => {
    onToggle(event.target.checked);
  };
  return <MUISwitch checked={checked} onChange={onChange} />;
};

export default UseSwitchesCustom;
