const { useState, useCallback } = require('react');

const useElementSize = () => {
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);

  const ref = useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  return { height, width, ref };
};

export default useElementSize;
