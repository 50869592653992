import React from 'react';
import { Box, Button, CssBaseline, Typography } from '@mui/material';

import { useLogIn } from '../helpers/UserContext';
import { translate } from '../helpers/localization';

import * as Theme from '../style/themes';

const Settings = () => {
  const logOut = useLogIn();
  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: Theme.pagePadding,
          fontWeight: 'bold',
          flex: 0,
        }}
      >
        <Typography variant="h5">{translate('menu.settings')}</Typography>
      </Box>
      <Box sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Button type="submit" sx={{ width: '100px' }} onClick={() => logOut(null)} variant="contained">
          Log Out
        </Button>
      </Box>
    </>
  );
};
export default Settings;
