import { Card } from '@mui/material';
import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Modal from './common/Modal';

const ProfileModal = ({ athlete, setOpen }) => {
  const title = athlete.name;

  const rows = Object.entries(athlete).map(([key, value]) => ({ key, value }));

  return (
    <Modal sx={{ flexDirection: 'row', alignItems: 'flex-start' }} title={title} open={!!athlete} setOpen={setOpen}>
      <Card sx={{ p: 1, m: 1, flexDirection: 'column' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Dessert (100g serving)</TableCell>
              <TableCell align="right">Calories</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.key}
                </TableCell>
                <TableCell align="right">{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </Modal>
  );
};
export default ProfileModal;
