import { createTheme } from '@mui/material';

export const colorBlue = '#0091FF';
export const colorGrayLight = '#ECEFF5';
export const colorGray = '#C9CED4';
export const colorBlueGray = '#9FB1CC';

export const colorGreen = '#86DE2A';
export const colorPink = '#FFA78A';
// const cBlue = '#3179F8';
export const colorRed = '#E02020';
export const colorOrange = '#FF7D00';
export const colorWhite = '#ffffff';

export const colorGradientBlue = '#0091FF';
export const colorGradientBlueGreen = '#1BD2E3';
export const colorGradientGreen = '#21EE91';

export const pagePadding = '16px';
export const fullscreen = { display: 'flex', flex: '1 0 auto', width: '100%' };
export const bgImage = url => ({ background: `url("${url}") no-repeat center center / cover` });

export const viewWidth = 54;

export const centerHV = { position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' };

export const theme = createTheme({
  typography: {
    fontFamily: ['Metropolis'],
  },
  palette: {
    type: 'light',
    primary: {
      main: colorBlue,
    },
    secondary: {
      main: '#f50057',
    },
    danger: {
      main: colorRed,
    },
    warning: {
      main: colorOrange,
    },
    success: {
      main: colorGreen,
    },
  },
  display: 'flex',
  blah: 'blah',
  shape: {
    borderRadius: 10,
  },
});
