import React, { useEffect, useState } from 'react';
import { Avatar, Box, Card, CardActionArea, Chip, Typography } from '@mui/material';
import moment from 'moment';

import * as Theme from '../style/themes';
import useAxios from '../helpers/api';

import { translate, getFormattedTime } from '../helpers/localization';

import { getCarbohydrateGramsPerHour, nutrientSum } from '../helpers/food';
import { ACTIVITY_SOURCES, MILLIGRAM_TO_GRAMS, SECONDS_TO_HOURS } from '../constants/constants';

import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TrackerLogo from 'components/TrackerLogo';
import WorkoutChart from './WorkoutChart';
import { distanceAsText, durationAsText } from 'helpers/displayUnits';

const ActionWrapper = ({ onClick, children }) => {
  const style = { flexDirection: 'column', alignItems: 'stretch' };
  return onClick ? (
    <CardActionArea onClick={onClick} sx={style}>
      {children}
    </CardActionArea>
  ) : (
    <Box sx={{ ...style, flexDirection: 'row', justifyContent: 'space-between' }}>{children}</Box>
  );
};

const ActivityCard = props => {
  const { activity, foodplan = [], onClick } = props;
  const [imageUrl, setImage] = useState('./mountain.jpg');
  const [profile, setProfile] = useState();

  const api = useAxios();

  useEffect(() => {
    if (activity.sourceType === ACTIVITY_SOURCES.ROUTE && activity.sourceId && api) {
      api.images.getFromRoute(activity.userId, activity.sourceId).then(res => {
        const objectURL = URL.createObjectURL(res.data);
        setImage(objectURL);
      });
    } else if (activity.profile) {
      setImage(activity.profile === 'mountain' ? './mountain.jpg' : activity.profile === 'hill' ? './hill.jpg' : './flat.jpg');
    }
  }, [api, activity]);

  useEffect(() => {
    (async () => {
      const profile = await api.profile.get();

      setProfile(profile.data);
    })();
  }, [api.profile]);

  if (!activity) {
    return null;
  }

  const hasFoodplan = foodplan.length > 0;
  const isCompleted = !!activity.completedActivityId || !!activity.evaluatedActivityId;
  const isPending = activity.pending;
  const activityType = isCompleted
    ? translate('activity.status.complete')
    : hasFoodplan
    ? translate('activity.status.has_plan')
    : isPending
    ? translate('activity.status.pending')
    : translate('activity.status.no_plan');
  const chipColor = isCompleted ? 'success' : hasFoodplan ? 'primary' : 'warning';
  const chipTextColor = Theme.colorWhite;
  const avatarColor = isCompleted ? Theme.colorGreen : hasFoodplan ? Theme.colorBlue : Theme.colorOrange;

  const carbsFromProducts = nutrientSum(foodplan, 'carbohydrates') / MILLIGRAM_TO_GRAMS;

  const gramPerHourFromFood = carbsFromProducts / ((activity?.duration || 1) / SECONDS_TO_HOURS);

  const carbsPerHourTotal = getCarbohydrateGramsPerHour(activity);

  const targetReached = gramPerHourFromFood >= carbsPerHourTotal * 0.9;

  const durationText = getFormattedTime(activity.duration, false);

  const title = (() => {
    if (activity.sourceType === ACTIVITY_SOURCES.WORKOUT) {
      return activity.source.title || durationAsText(activity.duration);
    }

    if (activity.label) {
      return activity.label;
    }

    return `${distanceAsText(activity.distance, profile?.measurementSystem)} ${
      activity.subSport === 'indoor' ? `${translate('activity.sub_sports.indoor')} ` : ''
    }${translate('routes.ride_label')}`;
  })();

  return (
    <Card sx={{ my: 1, flex: '0 0 auto' }}>
      <ActionWrapper onClick={isPending ? null : onClick}>
        <Box
          sx={{
            px: '2em',
            py: '1.5em',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ alignItems: 'center' }}>
            <Avatar
              sx={{
                bgcolor: avatarColor,
                height: '1.6em',
                width: '1.6em',
                flex: '0 0 auto',
              }}
            >
              <DirectionsBikeIcon sx={{ fontSize: '1em' }} />
            </Avatar>
            <Typography sx={{ pl: 2, flex: '0 0 4em', textAlign: 'center' }}>{`${moment(activity.date).format('HH:mm')}`}</Typography>
            <Typography sx={{ fontWeight: 800, px: '1em', flex: '0 0 14em', flexGrow: 1 }}>{title}</Typography>

            {!!carbsPerHourTotal && (
              <Box
                sx={{
                  flex: '0 0 16em',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    flex: '0 0 3em',
                    px: 1,
                    fontWeight: 700,
                    textAlign: 'end',
                  }}
                  color={!targetReached ? 'warning.main' : null}
                >
                  {Math.round(gramPerHourFromFood)}
                </Typography>
                <Typography
                  sx={{
                    flex: '0 0 3em',
                    fontWeight: 700,
                    textAlign: 'start',
                  }}
                >{`/ ${Math.round(carbsPerHourTotal)}`}</Typography>
                <Typography
                  sx={{
                    flex: '0 0 7em',
                    ml: 0.5,
                    fontWeight: 700,
                    color: Theme.colorBlueGray,
                  }}
                >
                  g / h
                </Typography>
              </Box>
            )}
          </Box>
          <Box sx={{ flex: '0 0 auto', px: 2, alignItems: 'center' }}>
            <Typography sx={{ fontWeight: 700, color: Theme.colorBlueGray }}>Time</Typography>
            <Typography sx={{ px: 1, fontWeight: 700 }}>{`${durationText} h`}</Typography>
          </Box>

          <Chip
            sx={{
              borderRadius: '0.5em',
              fontSize: '1em',
              flex: '0 0 8em',
              color: chipTextColor,
              justifySelf: 'flex-end',
            }}
            color={chipColor}
            label={activityType}
          />
          {!isPending && <ChevronRightIcon sx={{ ml: 1, mr: -1, color: Theme.colorBlueGray }} />}
        </Box>
        {!isPending &&
          (activity.sourceType === 'workout' ? (
            <Box position="relative">
              <WorkoutChart workout={activity.source} height={300} />
              <TrackerLogo tracker={activity?.source?.source} style={{ position: 'absolute', left: '15px', top: '15px' }} size={45} fill />
            </Box>
          ) : (
            imageUrl && (
              <Box>
                <img
                  style={{
                    margin: '0 2em 1.5em 2em',
                    borderRadius: '0.5em',
                    height: '20em',
                    width: '100%',
                    objectFit: 'cover',
                  }}
                  alt=""
                  src={imageUrl}
                />
                <TrackerLogo tracker={activity?.source?.source} style={{ position: 'absolute', left: '15px', top: '15px' }} size={45} fill />
              </Box>
            )
          ))}
      </ActionWrapper>
    </Card>
  );
};

export default ActivityCard;
