import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useLocalStorage } from './useLocalStorage';
import Urls from '../constants/urls';

const UserContext = React.createContext();
const LogInContext = React.createContext();

export const useUser = () => useContext(UserContext);
export const useLogIn = () => useContext(LogInContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [userId, setUserId] = useLocalStorage('userId');
  const [userToken, setUserToken] = useLocalStorage('userToken');
  const [userRole, setUserRole] = useLocalStorage('userRole');

  const updateUser = useCallback(
    user => {
      if (Urls().ENV === 'production') {
        window.smartlook('identify', user.email);
      }
      setUser(user);
      setUserId(user?.id);
      setUserToken(user?.token);
      const role = user?.coach ? 'coach' : user?.premium ? 'premium' : 'freemium';
      setUserRole(role);
    },
    [setUserId, setUserRole, setUserToken],
  );

  useEffect(() => {
    if (userId && userToken) {
      setUser({ id: userId, token: userToken, role: userRole });
    }
  }, [userId, userToken, userRole]);

  return (
    <UserContext.Provider value={user}>
      <LogInContext.Provider value={updateUser}>{children}</LogInContext.Provider>
    </UserContext.Provider>
  );
};
