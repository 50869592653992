import React from 'react';
import moment from 'moment';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';

import { translate } from '../../helpers/localization';
import * as Theme from '../../style/themes';

const ProfileMetric = ({ title, value }) => (
  <ListItemText
    sx={{ flexDirection: 'column', flex: '1 0 3em', alignItems: 'center' }}
    primary={title}
    secondary={value || '-'}
    primaryTypographyProps={{ fontSize: '0.8em', fontWeight: '700', color: Theme.colorBlueGray }}
    secondaryTypographyProps={{ fontSize: '0.8em', fontWeight: '900' }}
  />
);

const AthleteSummary = ({ athlete, hideChevron }) => {
  const gender = athlete.sex === 'M' ? translate('profile.gender.male') : athlete.sex === 'F' ? translate('profile.gender.female') : translate('error.unknown');

  const age = moment.utc(athlete.birthDate).format('YYYY');
  return (
    <>
      <ListItemAvatar sx={{ flex: 0, minWidth: 'unset', ml: '0.5em', mr: '1em', alignItems: 'center' }}>
        <Avatar sx={{ backgroundColor: Theme.colorBlueGray }} alt={athlete.name} src="/static/images/avatar/1.jpg" />
      </ListItemAvatar>

      {athlete.name ? (
        <>
          <Box
            sx={{
              flex: '1 1 auto',
              justifyContent: 'flex-start',
              overflow: 'hidden',
            }}
          >
            <ListItemText
              sx={{ flexDirection: 'column', flex: '4 0 4em' }}
              primary={athlete.name}
              secondary={athlete.email || '-'}
              primaryTypographyProps={{ fontWeight: '800' }}
              secondaryTypographyProps={{ fontSize: '0.8em', fontWeight: '900' }}
            />
            <ProfileMetric title={translate('profile.age')} value={age} />
            <ProfileMetric title={translate('profile.sex')} value={gender} />
            <ProfileMetric title={translate('profile.height')} value={athlete.height ? `${athlete.height} cm` : null} />
            <ProfileMetric title={translate('profile.weight')} value={athlete.weight ? `${athlete.weight} kg` : null} />
            <ProfileMetric title={translate('profile.vo2max')} value={athlete.VO2max} />
            <ProfileMetric title={translate('profile.ftp')} value={athlete.FTP} />
            <ProfileMetric title={translate('profile.fat')} value={athlete.fatPercentage ? `${athlete.fatPercentage} %` : null} />
          </Box>
          {!hideChevron && <ChevronRightIcon sx={{ flex: '0 0 2em', color: Theme.colorGray }} />}
        </>
      ) : (
        <ListItemText
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          primary={athlete.email}
          secondary={athlete.status}
          secondaryTypographyProps={{
            sx: {
              p: 1,
              color: athlete.status === 'pending' ? Theme.colorGray : Theme.colorRed,
              fontStyle: athlete.status === 'pending' ? 'italic' : null,
            },
          }}
        />
      )}
    </>
  );
};

export default AthleteSummary;
