import React, { useEffect, useState } from 'react';
import { Box, Card, Typography } from '@mui/material';
import * as Theme from '../style/themes';

import { getIndicators } from '../helpers/indicators';
import { INDICATOR, CARB_TO_CAL_MULTIPLIER, MILLIGRAM_TO_GRAMS } from '../constants/constants';
import { nutrientSum, getCaloriesFromNutrients, getCaloriesNeeded } from '../helpers/food';

const INDICATORS = [INDICATOR.CARBOHYDRATES, INDICATOR.PROTEINS, INDICATOR.FATS];
const DayPlanStats = ({ meals, foodplan, activity }) => {
  const style = { background: `linear-gradient(to right, ${Theme.colorGradientBlue}, ${Theme.colorGradientBlueGreen}, ${Theme.colorGradientGreen})` };

  const [calories, setCalories] = useState();
  const [caloriesNeeded, setCaloriesNeeded] = useState(0);
  const [indicators, setIndicators] = useState();

  useEffect(() => {
    const foodList = [];
    let calories = 0;
    let caloriesNeeded = 0;
    const nutrientsNeeded = { carbohydrates: 0, proteins: 0, fats: 0 };

    if (!meals) {
      return;
    }

    meals?.forEach(item => {
      const suggested = item.nutrientsNeeded;

      if (item.products?.length) {
        foodList.push(...item.products);

        calories += item.products ? nutrientSum(item.products, 'calories') : 0;
      }

      caloriesNeeded += Math.round(getCaloriesFromNutrients(suggested));

      if (suggested) {
        const { carbohydrates, proteins, fats } = suggested;
        nutrientsNeeded.carbohydrates += carbohydrates;
        nutrientsNeeded.proteins += proteins;
        nutrientsNeeded.fats += fats;
      }
    });

    if (activity?.caloriesNeeded) {
      caloriesNeeded += getCaloriesNeeded(activity);

      nutrientsNeeded.carbohydrates += (getCaloriesNeeded(activity) / CARB_TO_CAL_MULTIPLIER) * MILLIGRAM_TO_GRAMS;

      // add scheduled activity products for calculating nutrients
      foodplan?.forEach(item => {
        if (item.time > 0) {
          // only count nutrients from scheduled foodplan products
          foodList.push(item);
          calories += (item.product.calories * (item.gram || item.ml || 1)) / item.product.ingredientsQty;
        }
      });
    }
    setCalories(Math.round(calories));
    setCaloriesNeeded(Math.round(caloriesNeeded));

    setIndicators(getIndicators(INDICATORS, foodList, nutrientsNeeded));
  }, [meals, activity, foodplan]);

  const percentage = !calories ? 0 : calories > caloriesNeeded ? 1 : calories / caloriesNeeded;

  const endX = Math.cos(percentage * Math.PI);
  const endY = Math.sin(percentage * Math.PI);

  const arcStyle = {
    position: 'absolute',
    width: '16em',
    height: '16em',
    top: 0,
    border: '0.5em solid',
    borderColor: '#ffffff44',
    borderRadius: '8em',
    transform: 'rotate(-45deg)',
  };

  const arcStyleFilled = {
    ...arcStyle,
    borderColor: 'white white transparent transparent',
    transform: `rotate(-${Math.PI / 4 + (1 - percentage) * Math.PI}rad)`,
  };

  const rightEnd = {
    position: 'absolute',
    width: '0.5em',
    height: '0.25em',
    border: '0',
    borderBottomLeftRadius: '0.25em',
    borderBottomRightRadius: '0.25em',
    backgroundColor: '#ffffff44',
    transform: 'translate(7.75em, 0.24em)',
  };

  const rightEndFilled = {
    ...rightEnd,
    width: '0.5em',
    height: '0.5em',
    borderRadius: '0.25em',
    backgroundColor: 'white',
    transform: `translate(${-7.75 * endX}em, ${0.25 - 7.75 * endY}em)`,
  };

  const leftEnd = {
    ...rightEnd,
    backgroundColor: calories > 0 ? 'white' : '#ffffff44',
    transform: 'translate(-7.75em, 0.24em)',
  };

  return (
    <Card sx={{ color: Theme.colorWhite, my: 1, flex: '0 0 12em' }} style={style}>
      <Box
        style={{ flex: '2 0 20em', height: '8em', position: 'relative' }}
        sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', alignSelf: 'center' }}
      >
        <Box style={{ overflow: 'hidden', position: 'absolute', height: '8em', width: '16em' }}>
          <Box style={arcStyle} />
          {percentage ? <Box style={arcStyleFilled} /> : null}
        </Box>
        <Box style={leftEnd} />
        <Box style={rightEnd} />
        {calories > 0 && <Box style={rightEndFilled} />}
        {calories > 0 && (
          <Box sx={{ alignItems: 'baseline', justifyContent: 'center', flex: '0 0 auto' }}>
            <Typography variant="h3" sx={{ alignItems: 'center', justifyContent: 'center' }}>
              {calories}
            </Typography>
            <Typography variant="h5">kcal</Typography>
          </Box>
        )}
        {caloriesNeeded > 0 && (
          <Box sx={{ alignItems: 'baseline', justifyContent: 'center', flex: '0 0 auto' }}>
            <Typography variant={calories ? 'h5' : 'h3'}>{`${calories ? 'of ' : ''}${caloriesNeeded}`}</Typography>
            {!calories && <Typography variant="h5">kcal</Typography>}
          </Box>
        )}
      </Box>
      <Box sx={{ flex: '1 0 16em', flexDirection: 'column', justifyContent: 'space-evenly' }}>
        {indicators?.map(data => (
          <Indicator data={data} />
        ))}
      </Box>
    </Card>
  );
};

const Indicator = ({ data: { title, subtitle } }) => (
  <Box sx={{ flex: '0 0 auto', width: '16em', alignItems: 'center', justifyContent: 'space-between' }}>
    <Box sx={{ flex: '0 0 7em', justifyContent: 'flex-end', fontWeight: 800 }}>{subtitle}</Box>
    <Box sx={{ flex: '0 0 1em' }} />
    <Box>{title}</Box>
  </Box>
);

export default DayPlanStats;
