import { CARB_TO_CAL_MULTIPLIER, DEFAULT_FLUIDS_PER_HOUR, INDICATOR, MAX_PRODUCT_SCORE, MILLIGRAM_TO_GRAMS, SECONDS_TO_HOURS } from '../constants/constants';
import { fluidsSum, getCaloriesFromNutrients, getCaloriesNeeded, getCarbohydrateGramsPerHour, getFoodListRating, nutrientSum } from './food';
import { translate } from './localization';
import { convertMeasurement } from './measurementConverter';

export const getIndicators = (enabledIndicators, foodList, nutrientsNeeded, activity, scheduledOnly, measurementSystem) => {
  const indicators = [];

  if (foodList) {
    const filteredList = scheduledOnly ? foodList.filter(f => f.time > -1) : foodList;

    const carbsFromProducts = nutrientSum(filteredList, 'carbohydrates') / MILLIGRAM_TO_GRAMS;

    const gramPerHourTotal = getCarbohydrateGramsPerHour(activity);

    enabledIndicators.forEach(item => {
      const type = item.trim();

      switch (type) {
        case INDICATOR.CARBS_PER_HOUR:
          // CARBOHYDRATES GRAMS PER HOUR
          const gramPerHourFromFood = Math.round(carbsFromProducts / ((activity?.duration || 1) / SECONDS_TO_HOURS));
          if (gramPerHourTotal > 0) {
            indicators.push({
              title: translate('foodplan.quality_indicators.carbohydrates'),
              score: Math.round(Math.min(gramPerHourFromFood / gramPerHourTotal, 1) * MAX_PRODUCT_SCORE),
              subtitle: `${Math.round(gramPerHourFromFood)} ${translate('global.of')} ${Math.round(gramPerHourTotal)} ${translate(
                'foodplan.quality_indicators.g_p_hr',
              )}`,
              type,
            });
          }
          break;
        case INDICATOR.ENERGY:
          // ENERGY INTAKE, count only calories from carbohydrates during a ride
          const calories = carbsFromProducts * CARB_TO_CAL_MULTIPLIER;
          const caloriesNeeded = getCaloriesNeeded(activity);
          const energyScore = Math.round(Math.min(calories / caloriesNeeded, 1) * MAX_PRODUCT_SCORE);
          if (caloriesNeeded > 0) {
            indicators.push({
              title: translate('foodplan.quality_indicators.energy_level'),
              score: energyScore,
              subtitle: energyScore > 0 ? translate(`product.rating_${energyScore}`) : '',
              type,
            });
          }
          break;
        case INDICATOR.RATING:
          // PRODUCT SCORES
          const productScore = getFoodListRating(filteredList, gramPerHourTotal, scheduledOnly);
          indicators.push({
            title: translate('foodplan.quality_indicators.nutrition_quality'),
            score: productScore,
            subtitle: productScore > 0 ? translate(`product.rating_${productScore}`) : '',
            type,
          });
          break;
        case INDICATOR.FLUIDS:
          // FLUID INTAKE SCORE

          const fluidsFromProducts = Math.round((fluidsSum(filteredList, 'fluids') || 0) / 10) / 100;
          const totalFluidsNeeded = Math.round(((activity.fluidsPerHour || DEFAULT_FLUIDS_PER_HOUR) * activity.duration) / SECONDS_TO_HOURS / 10) / 100;

          const subtitle =
            measurementSystem === 'imperial'
              ? `${convertMeasurement(fluidsFromProducts, 'l/h', 'fl-oz/h')} ${translate('global.of')} ${convertMeasurement(
                  totalFluidsNeeded,
                  'l/h',
                  'fl-oz/h',
                )} ${translate('units.fl-oz.short')}`
              : `${fluidsFromProducts} ${translate('global.of')} ${totalFluidsNeeded} ${translate('units.l.long')}`;
          indicators.push({
            title: translate('foodplan.quality_indicators.fluid'),
            score: (fluidsFromProducts / totalFluidsNeeded) * MAX_PRODUCT_SCORE,
            subtitle,
            type,
          });
          break;
        case INDICATOR.KCAL:
          const calorieCount = nutrientSum(filteredList, 'calories');
          // count calories from carbs, proteins and fats for pre-exercise
          const totalCaloriesFromNutrients = getCaloriesFromNutrients(nutrientsNeeded);

          if (totalCaloriesFromNutrients > 0) {
            indicators.push({
              title: translate('foodplan.quality_indicators.energy_level'),
              score: (calorieCount / totalCaloriesFromNutrients) * MAX_PRODUCT_SCORE,
              subtitle: `${Math.round(calorieCount)} ${translate('global.of')} ${Math.round(totalCaloriesFromNutrients)} ${translate('units.kcal')}`,
              type,
            });
          }
          break;
        case INDICATOR.CARBOHYDRATES:
          const carbohydrates = nutrientSum(filteredList, 'carbohydrates') / MILLIGRAM_TO_GRAMS;
          const totalCarbohydrates = (nutrientsNeeded?.carbohydrates || 0) / MILLIGRAM_TO_GRAMS;
          indicators.push({
            title: translate('product.carbohydrates'),
            score: totalCarbohydrates > 0 ? (carbohydrates / totalCarbohydrates) * MAX_PRODUCT_SCORE : 0,
            subtitle:
              (carbohydrates ? `${Math.round(carbohydrates)} ${translate('global.of')} ` : '') +
              (totalCarbohydrates > 0 ? `${Math.round(totalCarbohydrates)} ${translate('units.g.short')}` : ' '),
            type,
          });
          // }
          break;
        case INDICATOR.FATS:
          const fat = nutrientSum(filteredList, 'fat') / MILLIGRAM_TO_GRAMS;
          const totalFat = (nutrientsNeeded?.fats || 0) / MILLIGRAM_TO_GRAMS;
          // if (totalFat > 0) {
          indicators.push({
            title: translate('product.fat'),
            score: totalFat > 0 ? (fat / totalFat) * MAX_PRODUCT_SCORE : 0,
            subtitle:
              (fat ? `${Math.round(fat)} ${translate('global.of')} ` : '') + (totalFat > 0 ? `${Math.round(totalFat)} ${translate('units.g.short')}` : ' '),
            type,
          });
          // }
          break;
        case INDICATOR.PROTEINS:
          const proteins = nutrientSum(filteredList, 'protein') / MILLIGRAM_TO_GRAMS;
          const totalProteins = (nutrientsNeeded?.proteins || 0) / MILLIGRAM_TO_GRAMS;
          indicators.push({
            title: translate('product.protein'),
            score: totalProteins > 0 ? (proteins / totalProteins) * MAX_PRODUCT_SCORE : 0,
            subtitle:
              (proteins ? `${Math.round(proteins)} ${translate('global.of')} ` : ' ') +
              (totalProteins > 0 ? `${Math.round(totalProteins)} ${translate('units.g.short')}` : ''),
            type,
          });
          break;
        default:
          break;
      }
    });
  }
  return indicators;
};
