import React from 'react';
import { ReactComponent as StravaLogo } from 'images/square/strava.svg';
import { ReactComponent as GarminLogo } from 'images/square/garmin.svg';
import { ReactComponent as WahooLogo } from 'images/square/wahoo.svg';
import { ReactComponent as TrainingPeaksLogo } from 'images/square/training-peaks.svg';
import { ReactComponent as RideWithGPSLogo } from 'images/square/rwgps.svg';
import { ReactComponent as KomootLogo } from 'images/square/komoot.svg';
import { Box } from '@mui/material';

const logos = {
  strava: StravaLogo,
  garmin: GarminLogo,
  wahoo: WahooLogo,
  trainingPeaks: TrainingPeaksLogo,
  rwgps: RideWithGPSLogo,
  komoot: KomootLogo,
};

const TrackerLogo = ({ tracker, size = 30, fill = false, style, ...props }) => {
  const Logo = logos[tracker];

  if (!Logo) {
    return null;
  }

  const styleProps = {
    backdrop: {
      height: size,
      width: size,
      backgroundColor: fill ? 'white' : 'transparent',
      borderRadius: 10,
    },
    logo: { resizeMode: 'contain' },
  };

  return (
    <Box
      style={{
        height: size,
        width: size,
        backgroundColor: fill ? 'white' : 'transparent',
        borderRadius: 10,
        ...style,
      }}
      {...props}
    >
      <Logo color="white" height={size} width={size} style={styleProps.logo} />
    </Box>
  );
};

export default TrackerLogo;
