import React, { useEffect, useRef, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Box, Button, Card, CssBaseline, IconButton, ListItemButton, Typography } from '@mui/material';

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useModal } from '../../helpers/useModal';

import RefreshIcon from '@mui/icons-material/Sync';
import LoadingButton from '@mui/lab/LoadingButton';
import LogoutIcon from '@mui/icons-material/Logout';

import { translate } from '../../helpers/localization';
import * as Theme from '../../style/themes';
import useAxios from '../../helpers/api';
import AthleteSummary from './AthleteSummary';
import { useLogIn } from '../../helpers/UserContext';

export const InviteDialog = ({ open, setOpen, sendInvite }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubscribe = e => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const credentials = {
      email: data.get('email'),
    };
    sendInvite(credentials);

    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box component="form" onSubmit={handleSubscribe} noValidate>
        <DialogTitle>Invite an athlete</DialogTitle>
        <DialogContent sx={{ flexDirection: 'column' }}>
          <DialogContentText>Enter an email address to invite an athlete to add themselves to your dashboard</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            required
            autoComplete="email"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Invite</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

const AthleteListContainer = ({ isCoach, setAthlete, showProfile }) => {
  const [athletes, setAthletes] = useState([]);
  const api = useAxios();
  const [refresh, setRefresh] = useState(new Date());
  const setModal = useModal();
  const [disableRefresh, setDisableRefresh] = useState(true);
  const timer = useRef();
  const [showInviteAthlete, toggleInviteAthlete] = useState(false);
  const logOut = useLogIn();

  useEffect(() => {
    if (refresh) {
      const statusOrder = ['approved', 'pending', 'denied', 'cancelled'];
      setRefresh();
      api.profile
        .get()
        .then(({ data }) => {
          if (!Array.isArray(data)) {
            data = [data];
          }
          setAthletes(
            data
              .map(user => ({
                ...user,
                id: user.userId,
                name: user.firstName,
              }))
              .sort((a, b) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)),
          );
        })
        .finally(() => {
          setRefresh();
          timer.current = setTimeout(() => setDisableRefresh(false), 20000);
        });
    }
  }, [api, refresh]);

  useEffect(() => clearTimeout(timer.current), []);

  const startRefresh = () => {
    if (refresh) return;
    setRefresh(new Date());
    setDisableRefresh(true);
  };

  const sendInvite = credentials => {
    api.user
      .invite(credentials)
      .then(response => {
        if (response.data === 'success') {
          startRefresh();
        }
        const text = response.data;
        const severity = response.data === 'success' ? 'success' : 'error';
        setModal({ text, severity });
      })
      .catch(error => {
        const text = error.response.data;
        setModal({ text, severity: 'error' });
      });
  };

  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: Theme.pagePadding,
          fontWeight: 'bold',
          flex: 0,
        }}
      >
        <Typography sx={{ fontSize: ' 1.4em', fontWeight: 800 }}>{translate('menu.athletes')}</Typography>
        <Box sx={{ flex: '0 0 auto' }}>
          <LoadingButton
            disabled={disableRefresh}
            onClick={startRefresh}
            endIcon={disableRefresh && !refresh ? null : <RefreshIcon />}
            loading={!!refresh}
            loadingPosition={disableRefresh && !refresh ? null : 'end'}
            variant="outline"
            sx={{ minWidth: '12em' }}
          >
            {disableRefresh && !refresh ? 'Up-to-date' : 'Refresh'}
          </LoadingButton>
          <IconButton onClick={logOut}>
            <LogoutIcon />
          </IconButton>
        </Box>
      </Box>
      <Card sx={{ m: 2, boxShadow: 4, flex: '0 0 auto' }}>
        <AthleteList athletes={athletes} setAthlete={setAthlete} toggleInviteAthlete={isCoach ? toggleInviteAthlete : null} showProfile={showProfile} />
      </Card>
      {InviteDialog({ open: showInviteAthlete, setOpen: toggleInviteAthlete, sendInvite })}
    </>
  );
};

const AthleteList = ({ athletes, setAthlete, toggleInviteAthlete, showProfile }) => (
  <List disablePadding sx={{ flexDirection: 'column' }}>
    {athletes.map((athlete, i) => (
      <React.Fragment key={i}>
        <ListItem disablePadding alignItems="flex-start" sx={{ flex: '0 0 auto' }}>
          <ListItemButton onClick={athlete.id ? () => setAthlete(athlete) : null} sx={{ flexDirection: 'row', minHeight: 72 }}>
            <AthleteSummary athlete={athlete} showProfile={showProfile} />
          </ListItemButton>
        </ListItem>
        {toggleInviteAthlete && <Divider component="li" />}
      </React.Fragment>
    ))}
    {toggleInviteAthlete && (
      <ListItem key="invite" disablePadding alignItems="flex-start" sx={{ minHeight: 72, alignItems: 'stretch', flexDirection: 'column' }}>
        <ListItemButton onClick={() => toggleInviteAthlete(true)} sx={{ flex: 1, flexDirection: 'row' }}>
          <ListItemAvatar sx={{ flex: 0, minWidth: 'unset', ml: '0.5em', mr: '1em', alignItems: 'center' }}>
            <Avatar sx={{ backgroundColor: Theme.colorBlueGray }} alt="+" src="/" />
          </ListItemAvatar>
          <ListItemText sx={{ flexDirection: 'column' }} primary="Invite a new athlete" />
        </ListItemButton>
      </ListItem>
    )}
  </List>
);

export default AthleteListContainer;
