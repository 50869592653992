import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, CssBaseline, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import moment from 'moment';

import * as Theme from '../../style/themes';
import AthleteList from './AthleteList';
import AthleteSummary from './AthleteSummary';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import useAxios from '../../helpers/api';
import Calendar from '../Calendar';
import ActivityCard from '../ActivityCard';
import MealCard from '../MealCard';
import ProfileModal from '../ProfileModal';
import ActivityModal from './ActivityModal';
import MealModal from '../MealModal';
import CircularProgress from '@mui/material/CircularProgress';
import { translate } from '../../helpers/localization';
import DatePicker from '../common/DatePicker';
import DayPlanStats from '../DayPlanStats';

const AthleteContainer = ({ isCoach, showLogOut }) => {
  const [selectedAthlete, setAthlete] = useState();
  const [currentDate, setCurrentDate] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showActivity, setShowActivity] = useState();
  const [showMeal, setShowMeal] = useState();
  const [activityDates, setActivityDates] = useState([]);
  const [dayActivities, setDayActivities] = useState([]);
  const [foodplan, setFoodplan] = useState();
  const [dayMeals, setDayMeals] = useState();
  const [timeline, setTimeline] = useState([]);

  const [showDatePicker, setShowDatePicker] = useState(false);

  const setDate = date => {
    const daysDifference = -moment().startOf('day').diff(date, 'days');

    setCurrentDate(daysDifference);
  };

  const api = useAxios();

  const loadDayData = useCallback(async (api, userId, currentDate) => {
    const date = moment().add(currentDate, 'days').set({ hour: 0, minute: 0 }).toISOString(true);

    const { data: dayData } = await api.day.get(userId, date);
    const { data: mealsData } = await api.day.getMeals(userId, date);
    const { data: activityData } = await api.day.getActivities(userId, date);

    setTimeline([]);
    setDayActivities([]);
    setDayMeals(mealsData);

    if (dayData?.completedActivities?.[0]) {
      const { duration_move: duration, ...completedActivityData } = dayData.completedActivities[0];
      const label = completedActivityData.label || translate('activity.completed.default_title');
      const completedActivity = { duration, label, ...completedActivityData, pending: true };

      setDayActivities(activities => (activities.length > 0 ? [...activities] : [completedActivity]));
    }

    if (activityData.length) {
      setDayActivities(activityData);
    }
  }, []);

  const getActivityDates = useCallback(
    dateOffset => {
      const userId = selectedAthlete.id;
      api.activity
        .getAll(
          userId,
          moment().add(dateOffset, 'days').format(),
          moment()
            .add(dateOffset + 30, 'days')
            .format(),
        )
        .then(({ data }) => {
          const COLOR_TYPES = ['primary', 'warning', 'success'];

          const ACTIVITY_TYPES = ['planned', 'completed', 'evaluated'];

          const activityDates = data.reduce((dates, activity) => {
            const date = moment.utc(activity.date).local().startOf('day').format();
            const index = Math.max(COLOR_TYPES.indexOf(dates[date]), ACTIVITY_TYPES.indexOf(activity.type), 0);
            dates[date] = COLOR_TYPES[index];
            return dates;
          }, {});
          setActivityDates(activityDates);
        });
    },
    [api, selectedAthlete],
  );

  useEffect(() => {
    const activityId = dayActivities?.[0]?.id;
    const userId = selectedAthlete?.id;
    if (activityId && userId) {
      api.foodplan.get(userId, activityId).then(({ data }) => {
        setFoodplan(data);
      });
    }
  }, [api, selectedAthlete, dayActivities]);

  useEffect(() => {
    const activity = dayActivities?.[0];
    const showActivityModal = () => {
      setShowMeal();
      setShowActivity(activity);
    };

    const activityTime = activity?.date;
    const localActivityTime = activityTime ? moment(activityTime) : null;

    const activityListItem = !!activity && <ActivityCard key="activity" activity={activity} foodplan={foodplan} onClick={showActivityModal} />;
    let meals = dayMeals?.actual?.length ? dayMeals.actual : dayMeals?.suggested;
    if (dayMeals?.actual && dayMeals?.suggested) {
      meals = meals.map(meal => ({ ...meal, nutrientsNeeded: dayMeals.suggested.filter(m => m.time === meal.time)[0]?.nutrientsNeeded }));
    }

    const dayText = moment().add(currentDate, 'days').format('YYYY-MM-DD');
    let shouldShowActivity = !!localActivityTime;

    const timeline = meals
      ? meals.flatMap(m => {
          const dateTime = moment(`${dayText}T${m.time}`);
          const activity = shouldShowActivity && localActivityTime.isBefore(dateTime) && activityListItem;
          if (activity) {
            shouldShowActivity = false;
          }
          const showMealModal = () => {
            setShowActivity();
            setShowMeal(m);
          };

          const mealCard = <MealCard key={`meal-${m.time}`} meal={m} onClick={showMealModal} />;

          return [activity, mealCard];
        })
      : [];

    if (shouldShowActivity && activityListItem && timeline.length > 0) {
      timeline.push(activityListItem);
    }

    timeline.unshift(<DayPlanStats meals={meals} activity={activity} foodplan={foodplan} />);

    setTimeline(timeline);
  }, [currentDate, dayActivities, foodplan, dayMeals]);

  useEffect(() => {
    if (selectedAthlete?.id) {
      loadDayData(api, selectedAthlete.id, currentDate);
    }
  }, [api, selectedAthlete, currentDate, loadDayData]);

  const showProfileFor = selection => {
    if (selection?.id && selection.id !== selectedAthlete?.id) {
      setAthlete(selection);
    }
    setShowModal(true);
  };

  const CurrentDateText = () => {
    if (currentDate === 0) return translate('calendar.today');
    if (currentDate === -1) return translate('calendar.yesterday');
    if (currentDate === 1) return translate('calendar.tomorrow');

    return moment().add(currentDate, 'days').format('ddd DD MMM');
  };

  return selectedAthlete ? (
    <>
      <CssBaseline />
      <Box
        sx={{
          flexDirection: 'row',
          boxShadow: 2,
          flex: '0 0 5em',
          backgroundColor: 'white',
        }}
      >
        <Box sx={{ flexDirection: 'column', flex: '1 1 auto', alignItems: 'center' }}>
          <Box sx={{ py: '1em', width: `${Theme.viewWidth}em` }}>
            <IconButton sx={{ alignSelf: 'center' }} onClick={() => setAthlete()}>
              <ArrowBackIcon />
            </IconButton>
            <AthleteSummary athlete={selectedAthlete} showProfile={showProfileFor} hideChevron />
          </Box>
          <Box
            sx={{
              borderTopColor: Theme.colorGray,
              borderTop: `1px solid ${Theme.colorGray}`,
              alignSelf: 'stretch',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ flex: '0 0 auto' }}>
              <Calendar currentDate={currentDate} setCurrentDate={setCurrentDate} markedDates={activityDates} onSetStart={getActivityDates} />
              <Box
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderLeft: `1px solid ${Theme.colorGray}`,
                  borderRight: `1px solid ${Theme.colorGray}`,
                  flex: '0 0 auto',
                  width: '14em',
                }}
              >
                <IconButton onClick={() => setCurrentDate(currentDate => currentDate - 1)}>
                  <ChevronLeftIcon sx={{ color: Theme.colorGray }} />
                </IconButton>
                <Button onClick={() => setShowDatePicker(true)}>
                  <CurrentDateText />
                </Button>
                <IconButton onClick={() => setCurrentDate(currentDate => currentDate + 1)}>
                  <ChevronRightIcon sx={{ color: Theme.colorGray }} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          // flex: 2,
          flexDirection: 'column',
          padding: '12px',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          width: `${Theme.viewWidth}em`,
          alignSelf: 'center',
        }}
      >
        {timeline.length > 0 ? (
          timeline
        ) : (
          <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
      {showModal && <ProfileModal open={showModal} setOpen={setShowModal} athlete={selectedAthlete} />}
      {showActivity && <ActivityModal activity={showActivity} athlete={selectedAthlete} foodplan={foodplan} setOpen={setShowActivity} />}
      {showMeal && <MealModal meal={showMeal} athlete={selectedAthlete} setOpen={setShowMeal} />}
      {showDatePicker && <DatePicker value={moment().add(currentDate, 'days')} open={showDatePicker} setOpen={setShowDatePicker} setDate={setDate} />}
    </>
  ) : (
    <Box sx={{ flexDirection: 'column', alignSelf: 'center', width: `${Theme.viewWidth}em` }}>
      <AthleteList isCoach={isCoach} showLogOut={showLogOut} setAthlete={setAthlete} showProfile={showProfileFor} />
    </Box>
  );
};

export default AthleteContainer;
