import { en } from '../constants/localization';

const languageFile = en;

export const translate = key => {
  try {
    const keys = key.split('.');
    let value = languageFile;
    for (const key of keys) {
      value = value[key];
    }
    return value;
  } catch {
    return ' ';
  }
};

export const getFormattedTime = (seconds, zeroPadHour = true, showSeconds = false) => {
  const zeroPad = n => n.toString().padStart(2, '0');
  const s = seconds % 60;
  const m = ((seconds - s) / 60) % 60;
  const h = ((seconds - m * 60 - s) / 3600) % 24;
  return `${zeroPadHour ? zeroPad(h) : h}:${zeroPad(m)}${showSeconds ? `:${zeroPad(Math.round(s))}` : ''}`;
};

export const getFormattedDistance = distanceInMeters => `${Math.round(distanceInMeters / 100) / 10} km`;
