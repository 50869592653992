import React from 'react';
import { Box, Typography } from '@mui/material';

import * as Theme from '../style/themes';

const IndicatorBar = ({ color }) => (
  <Box
    sx={{
      flex: '1 0 1em',
      height: '0.4em',
      m: 0.25,
      backgroundColor: color,
      borderRadius: 0.5,
    }}
  />
);

const getColorForScore = score => {
  if (score >= 4) {
    return Theme.colorGreen;
  }
  if (score <= 2) {
    return Theme.colorRed;
  }
  return Theme.colorOrange;
};

const Indicator = ({ title, subtitle, emptyColor = Theme.colorGray, score = 0, pips = 5 }) => {
  const scoreColor = getColorForScore(score);
  return (
    <Box
      sx={{
        flexDirection: 'column',
        p: 1,
        m: 0.5,
        alignItems: 'center',
        flex: '0 0 10em',
      }}
    >
      <Typography color={Theme.colorBlueGray} sx={{ fontWeight: 800, fontSize: '0.8em' }}>
        {title}
      </Typography>
      <Box sx={{ alignSelf: 'stretch', justifyContent: 'center', flexDirection: 'row' }}>
        {[...Array(pips).keys()].map((_, i) => (
          <IndicatorBar key={i} color={i < score ? scoreColor : emptyColor} />
        ))}
      </Box>
      <Typography sx={{ fontWeight: 800, fontSize: '0.75em', height: '0.75em', pt: 0.75 }}>{subtitle}</Typography>
    </Box>
  );
};

export default Indicator;
